import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleResetRequest = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://readit-readit-com.onrender.com/api/forgot-password',
        { email }
      );
      alert(response.data.message);
    } catch (error) {
      alert(error.response?.data?.message || 'Request failed. Please try again.');
    }
  };

  return (
    <div className="forgot-password-container">
      <form className="forgot-password" onSubmit={handleResetRequest}>
      <div className="mini-spacer"></div>
        <h1>Forgot Password</h1>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="forgot-password-input"
        />
        <button type="submit" className="forgot-password-button">
          Request Password Reset
        </button>
        <div className="mini-spacer"></div>
      </form>
      <div className="mini-spacer"></div>
    </div>
  );
};

export default ForgotPassword;
